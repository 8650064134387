import { Container } from 'src/components/Container/styled';
import {
  ConcernsText,
  DescriptionText,
  SecurityTitle,
  ShamirSharingWrapper,
  TitleStyled,
} from './styled';
import shamirSharingPath from 'src/assets/img/shamir-sharing.webp';
import { useNavigate } from 'react-router-dom';
import { DownloadAppButton } from '../DownloadAppButton';

export const ShamirSharing = () => {
  const navigate = useNavigate();
  return (
    <Container style={{ marginBottom: '12.8vw', marginTop: '17.067vw' }}>
      <ShamirSharingWrapper>
        <TitleStyled>Haqabi will take care</TitleStyled>
        <ConcernsText>No concerns about seed phrase</ConcernsText>
        <SecurityTitle>Maximum security, zero hassle</SecurityTitle>
        <DescriptionText>
          Regain access with just 2 of 3 key parts, securely stored in private
          vaults only you control.{' '}
        </DescriptionText>
        <img src={shamirSharingPath} style={{ marginBottom: '10.667vw' }} />
        <DownloadAppButton />
      </ShamirSharingWrapper>
    </Container>
  );
};
