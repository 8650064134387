import styled from 'styled-components';

export const ContentStyled = styled.div``;
export const ContentSocialsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SocialsIconBlockStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: clamp(0px, 0.625vw, 9px);
`;
export const ContentSocialsTextStyled = styled.span`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: clamp(0px, 1.667vw, 20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export const SocialsBlockStyled = styled.div`
  display: flex;
  gap: clamp(0px, 0.556vw, 8px);
`;
export const ContentInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.333vw;
`;
export const InfoBlockStyled = styled.div`
  display: flex;
  gap: clamp(0px, 2.5vw, 36px);
  font-size: 3.889vw;
`;
export const InfoBlockTitleStyled = styled.a`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.389vw, 20px);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(0px, 1.458vw, 21px);
  cursor: pointer;
  text-decoration: none;
`;
export const InfoBlockTextStyled = styled.span`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.389vw, 20px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.458vw;
  letter-spacing: 0.01vw;
`;

export const BottomTextStyled = styled.span`
  color: #6a7484;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.25vw, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: 1.111vw;
  letter-spacing: 0.011vw;
  display: flex;
  justify-content: center;
  margin-top: 3.333vw;
`;

export const SocialLinkStyled = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2227;
  width: clamp(0px, 2.778vw, 40px);
  height: clamp(0px, 2.778vw, 40px);
  padding: clamp(0px, 0.347vw, 5px);
  border-radius: clamp(0px, 0.556vw, 8px);

  &:hover {
    background-color: #1a1e23;
  }
`;
