import React from 'react';
import { ReactComponent as IslmIcon } from 'src/assets/icons/islm.svg';
import { ReactComponent as RecoveryIcon } from 'src/assets/icons/recovery.svg';
import { ReactComponent as BuyIcon } from 'src/assets/icons/buy.svg';
import { ReactComponent as CommissionIcon } from 'src/assets/icons/commission.svg';
import { ReactComponent as InterfaceIcon } from 'src/assets/icons/interface.svg';
import { ReactComponent as LockIcon } from 'src/assets/icons/lock.svg';
import { ReactComponent as EducationIcon } from 'src/assets/icons/education.svg';

const size = { width: '7.467vw', height: '7.467vw' };

export const featureData = [
  {
    icon: <RecoveryIcon {...size} />,
    title: 'Seedless recovery',
  },

  {
    icon: <InterfaceIcon {...size} />,
    title: 'DApps',
  },

  {
    icon: <CommissionIcon {...size} />,
    title: 'Native staking',
  },
  {
    icon: <BuyIcon {...size} />,
    title: 'Buy Crypto',
  },
  {
    icon: <LockIcon {...size} />,
    title: 'Fraud Protection',
  },
  {
    icon: <EducationIcon {...size} />,
    title: 'Education',
  },
];
