import { ReactComponent as GithubIcon } from 'src/assets/icons/github.svg';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi.svg';
import { ReactComponent as LinkIcon } from 'src/assets/icons/link.svg';
import {
  ContentBlockStyled,
  ContentBlockSubTitle,
  ContentBlockTitleStyled,
  ContentBlocksStyled,
  ContentLineStyled,
  ContentTextStyled,
} from './styled';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { Container } from 'src/components/Container/styled';
import litePaperPdf from 'src/assets/files/lite-paper.pdf';

export const Documentation = ({ device }) => {
  const { isMobile } = device;

  const linkIconSIze = isMobile
    ? { width: '5.333vw', height: '5.333vw' }
    : {
        width: 'clamp(0px, 1.389vw, 20px)',
        height: 'clamp(0px, 1.389vw, 20px)',
      };

  return (
    <Container id="Documentation">
      <div style={{ marginTop: isMobile ? '20px' : '0' }}>
        <ContentTextStyled>Documentation</ContentTextStyled>
        <ContentBlocksStyled>
          <ContentBlockStyled
            onClick={() => window.open(litePaperPdf, '_blank')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                cursor: 'pointer',
                textDecoration: 'none',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '1.25vw' : 'clamp(0px, 1.25vw, 18px)',
                }}>
                <HaqabiIcon
                  width={isMobile ? '10.667vw' : 'clamp(0px, 3.889vw, 56px)'}
                  height={isMobile ? '10.667vw' : 'clamp(0px, 3.889vw, 56px)'}
                />
                <ContentBlockTitleStyled>Lite Paper</ContentBlockTitleStyled>
              </div>
              <ContentBlockSubTitle
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                Open <LinkIcon {...linkIconSIze} />
              </ContentBlockSubTitle>
            </div>
          </ContentBlockStyled>
          <ContentBlockStyled>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '1.25vw' : 'clamp(0px, 1.25vw, 18px)',
                }}>
                <GithubIcon
                  width={isMobile ? '10.667vw' : 'clamp(0px, 3.889vw, 56px)'}
                  height={isMobile ? '10.667vw' : 'clamp(0px, 3.889vw, 56px)'}
                />
                <ContentBlockTitleStyled>Github</ContentBlockTitleStyled>
              </div>
              <ContentBlockSubTitle $isDisabled>
                Coming soon
              </ContentBlockSubTitle>
            </div>
          </ContentBlockStyled>
        </ContentBlocksStyled>
      </div>
    </Container>
  );
};
