import { Container } from 'src/components/Container/styled';
import { BoxStyled, Solution, SolutionsWrapper, Title } from './styled';
import { ReactComponent as ZendeskIcon } from 'src/assets/icons/zendesk.svg';
import { ReactComponent as ElipticIcon } from 'src/assets/icons/eliptic.svg';
import { ReactComponent as SumSubIcon } from 'src/assets/icons/sumsub.svg';

const desktopSize = {
  width: 'clamp(0px, 12.5vw, 180px)',
  height: 'clamp(0px, 2.778vw, 45px)',
};

const tabletSize = {
  width: '20.833vw',
  height: '3.255vw',
};

const mobileSize = {
  width: '42.933vw',
  height: '10.667vw',
};

export const WorldClassSolutions = ({ device }) => {
  const iconSize = device.isMobile
    ? mobileSize
    : device.isTablet
      ? tabletSize
      : desktopSize;

  console.log('iconSize', iconSize);

  return (
    <Container style={{ padding: '0' }}>
      <BoxStyled>
        <Title>We operate based on world-class solutions</Title>
        <SolutionsWrapper>
          <Solution>
            <ElipticIcon {...iconSize} />
          </Solution>
          <Solution>
            <SumSubIcon {...iconSize} />
          </Solution>
          <Solution>
            <ZendeskIcon {...iconSize} />
          </Solution>
        </SolutionsWrapper>
      </BoxStyled>
    </Container>
  );
};
