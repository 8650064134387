import styled, { css } from 'styled-components';
export const ContentTextStyled = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 6.4vw;

  @media (min-width: 769px) {
    font-size: 2.778vw;
    margin-bottom: 2.5vw;
  }
  @media (min-width: 1025px) {
    font-size: clamp(0px, 2.222vw, 32px);
    margin-bottom: clamp(0px, 1.667vw, 24px);
  }
`;

export const ContentBlocksStyled = styled.div`
  display: flex;
  gap: 2.933vw;
  width: 100%;
  margin-bottom: 18.667vw;
  flex-direction: column;

  @media (min-width: 769px) {
    display: flex;
    gap: 1.667vw;
    margin-bottom: 9.722vw;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    height: clamp(0px, 7.222vw, 104px);
    margin-bottom: clamp(0px, 5.556vw, 80px);
  }
`;

const disabledStyled = isDisabled => {
  if (isDisabled)
    return css`
      border-radius: 1.934vw;
      border: 0.121vw solid rgba(255, 255, 255, 0.25);
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(1.149vw);
    `;
};

export const ContentBlockStyled = styled.a`
  text-decoration: none;
  border-radius: 1.934vw;
  backdrop-filter: blur(1.149vw);
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5.333vw;
  align-items: center;
  ${({ $isDisabled }) => disabledStyled($isDisabled)}
  background: #12161B;
  cursor: pointer;
  backdrop-filter: blur(7.083583831787109px);

  @media (min-width: 769px) {
    border-radius: 0.833vw;
    backdrop-filter: blur(0.492vw);
    padding: 1.667vw;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }
  @media (min-width: 1024px) {
    width: 50%;
    padding: clamp(0px, 2.222vw, 32px);
  }
`;
export const ContentBlockTextsStyled = styled.div`
  margin-right: ${props => (props.$ismobile ? '0' : '6.133vw')};
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.ismobile ? '0' : '3.2vw')};
`;
export const ContentBlockTitleStyled = styled.span`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.012vw;
  @media (min-width: 769px) {
    font-size: 1.458vw;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.012vw;
  }
  @media (min-width: 1024px) {
    font-size: clamp(0px, 1.389vw, 20px);
  }
`;
export const ContentBlockSubTitle = styled.span`
  color: ${({ $isDisabled }) => ($isDisabled ? '#8F96A3' : '#c4f538')};
  font-size: 4.267vw;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.027vw;
  margin-top: 1.067vw;
  gap: 2.133vw;
  @media (min-width: 769px) {
    font-size: 1.25vw;
    line-height: 100%;
    letter-spacing: 0.01vw;
    margin: 0;
    gap: clamp(0px, 0.694vw, 8px);
  }
  @media (min-width: 1024px) {
    font-size: clamp(0px, 1.25vw, 18px);
  }
`;

export const ContentLineStyled = styled.div`
  width: 100%;
  background-color: rgba(198, 207, 216, 0.4);
  opacity: 0.2;
  height: 0.267vw;
  @media (min-width: 769px) {
    height: 0.069vw;
  }
`;

export const IphoneMockup = styled.img`
  margin-top: 10.667vw;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (min-width: 769px) {
    margin-top: 2.778vw;
  }
`;
