import axios from 'axios';

let accessToken = '';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/bonusTree/`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'x-app-version': '1.17',
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise => {
    if (token) {
      promise.resolve(token);
    } else {
      promise.reject(error);
    }
  });
  failedQueue = [];
};

const rnMessageHandler = event => {
  let message;
  try {
    const res = JSON.parse(event?.data);
    message = res;
  } catch (e) {
    return;
  }
  if (message?.type === 'setAccessToken') {
    accessToken = message.data;
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
    processQueue(null, accessToken);
  }
};

window.addEventListener('message', rnMessageHandler);
document.addEventListener('message', rnMessageHandler);

api.interceptors.request.use(req => {
  return req;
});

api.interceptors.response.use(
  res => res,
  error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // Notify React Native to refresh the token
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: '401' }));

      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return api(originalRequest);
        })
        .catch(err => Promise.reject(err))
        .finally(() => {
          isRefreshing = false;
        });
    }

    return Promise.reject(error);
  },
);

export const postMapper = url => {
  return async (data, config) => {
    return (await api.post(url, data, config)).data;
  };
};

export const getMapper = url => {
  return async (params, config) => {
    return (await api.get(url, { ...config, params })).data;
  };
};

export default api;
