import { VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';

export const ShamirSharingWrapper = styled(VerticalView)``;
export const TitleStyled = styled.p`
  color: #c4f538;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4.8vw;
  font-weight: 500;
  margin-bottom: 2.133vw;
`;

export const ConcernsText = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 6.4vw;
  font-weight: 700;
  margin-bottom: 10.667vw;
`;

export const SecurityTitle = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4.8vw;
  font-weight: 600;
  margin-bottom: 2.133vw;
`;

export const DescriptionText = styled.p`
  color: #8f96a3;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  line-height: 140%;
  margin-bottom: 10.667vw;
`;
