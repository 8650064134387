import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './App';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import TermsAndUse from './pages/TermsOfUse';
import TermsAndUseApp from './pages/TermsOfUseApp';
import './index.scss';
import { CMS } from 'src/pages/CMS';
import Article from 'src/pages/CMS/views/article';
import { BreakpointProvider } from './hooks/useBreakpoint';
import { DeleteAccount } from './pages/DeleteAccount';
import { DownloadApk } from './pages/DownloadApk';
import { AirDrop } from './pages/AirDrop';
import './integrations/firabse';
import { MainPage } from './pages/BonusGame/screens/GamePage';
import { BonusGameRoutes } from './pages/BonusGame';
import { Education } from './pages/Main/components/Education';

const root = ReactDOM.createRoot(document.getElementById('root'));

const firstPathSegment = window.location.pathname.split('/')[1];

root.render(
  <BreakpointProvider>
    <BrowserRouter>
      {firstPathSegment === 'bonus-game' ? (
        <BonusGameRoutes />
      ) : (
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/terms-of-use" element={<TermsAndUse />} />
          <Route path="/terms-of-use-app" element={<TermsAndUseApp />} />
          <Route path="/privacy-policy-app" element={<PrivacyPolicy />} />
          <Route path="/download-apk" element={<DownloadApk />} />
          <Route path="/airdrop" element={<AirDrop />} />
          <Route path="/education/" element={<CMS />} />
          <Route path="/education/:articleId" element={<Article />} />
        </Routes>
      )}
    </BrowserRouter>
  </BreakpointProvider>,
);
