import styled from 'styled-components';
import buyCryptoBackground from 'src/assets/img/buy-crypto-background.webp';
import buyCryptoMobileBackground from 'src/assets/img/buy-crypto-opacity.webp';

export const BoxStyled = styled.div`
  display: flex;
  background-color: hsl(213, 20%, 8.8%);
  align-items: center;
  background-image: url(${buyCryptoBackground});
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  padding: clamp(0px, 5.278vw, 76px) clamp(0px, 6.944vw, 100px);
  @media (max-width: 1024px) {
    padding: 8.333vw 2.667vw;
    justify-content: center;
    background-position: 60%;
  }
  @media (max-width: 768px) {
    padding: 10.667vw 2.667vw;
    background-size: cover;
    background-position: 30%;
    background-image: url(${buyCryptoMobileBackground});
  }
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.p`
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.222vw, 32px);
  font-style: normal;
  font-weight: 700;
  margin-top: clamp(0px, 1.944vw, 28px);

  @media (max-width: 1024px) {
    font-size: 4.167vw;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 6.4vw;
    margin-top: 8.533vw;
  }
`;

export const InfoText = styled.p`
  color: #8f96a3;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  font-weight: 400;
  line-height: 140%;
  margin-top: clamp(0px, 1.111vw, 16px);

  @media (max-width: 1024px) {
    font-size: 2.083vw;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 4.267vw;
    margin-top: 4.267vw;
  }
`;
