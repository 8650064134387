import styled from 'styled-components';

export const TitleStyled = styled.p`
  margin-top: 20px;
  color: #fff;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 6.4vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 6.4vw;
  white-space: nowrap;

  @media (min-width: 769px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 3.333vw;
  }
`;
