import { featureData } from './data';
import {
  ContentBoxStyled,
  TextTypographyStyled,
  TitleStyled,
  FeatureItemStyled,
} from './styled';
import { Container } from 'src/components/Container/styled';
import takeChangeCryptoMobile from 'src/assets/img/take-change-crypto-mobile.png';

export const TakeChangeCrypto = () => {
  return (
    <Container id="Features">
      <TextTypographyStyled>Take charge of your crypto</TextTypographyStyled>
      <img src={takeChangeCryptoMobile} style={{ width: '100%' }} />
      <ContentBoxStyled>
        {featureData.map((feature, idx) => (
          <FeatureItem key={idx} feature={feature} idx={idx} />
        ))}
      </ContentBoxStyled>
    </Container>
  );
};

const FeatureItem = ({ feature }) => {
  return (
    <FeatureItemStyled>
      {feature.icon}
      <TitleStyled>{feature.title}</TitleStyled>
    </FeatureItemStyled>
  );
};
