import { useState, useEffect } from 'react';

export const useDeviceType = () => {
  const getDeviceType = () => {
    if (typeof window === 'undefined')
      return { isMobile: false, isTablet: false, isDesktop: false };

    const width = window.innerWidth;
    return {
      isMobile: width <= 768,
      isTablet: width > 768 && width <= 1024,
      isDesktop: width > 1024,
    };
  };

  const [device, setDevice] = useState(getDeviceType);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => setDevice(getDeviceType());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return device;
};
