import { Container } from 'src/components/Container/styled';
import {
  BoxStyled,
  ConcernsText,
  DescriptionText,
  ImageStyled,
  LeftSideWrapper,
  SecurityTitle,
  Title,
} from './styled';
import shamirSharingPath from 'src/assets/img/shamir-sharing.webp';
import { HorizontalView } from 'src/components/FlexViews';
import { DownloadAppButton } from '../DownloadAppButton';

export const ShamirSharingDesktop = ({ device }) => {
  const { isTablet } = device;
  return (
    <Container
      style={{
        padding: 'clamp(0px, 4.444vw, 64px)',
      }}>
      <BoxStyled>
        <LeftSideWrapper>
          <Title>Haqabi will take care</Title>
          <ConcernsText>No concerns about seed phrase</ConcernsText>
          <SecurityTitle>Maximum security, zero hassle</SecurityTitle>
          <DescriptionText>
            Regain access with just 2 of 3 key parts, securely stored in private
            vaults only you control.{' '}
          </DescriptionText>
          {!isTablet && <DownloadAppButton />}
        </LeftSideWrapper>
        <ImageStyled src={shamirSharingPath} />
      </BoxStyled>
      {isTablet && (
        <HorizontalView
          style={{ justifyContent: 'center', marginTop: '5.208vw' }}>
          <DownloadAppButton />
        </HorizontalView>
      )}
    </Container>
  );
};
