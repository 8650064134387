import styled, { css } from 'styled-components';

export const MainStyled = styled.div`
  margin-top: 8.533vw;
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 3.2vw;
  @media (min-width: 769px) {
    gap: 2.222vw;
    margin-top: 10.833vw;
  }
`;

export const TextStyled = styled('p')`
  color: var(--color-primary-5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 8.533vw;
  letter-spacing: 0.073vw;

  @media (min-width: 769px) {
    line-height: 100%;
    font-size: 1.25vw;
    letter-spacing: 0;
  }
`;

export const MainTextStyled = styled('p')`
  color: white;
  font-family: Inter, sans-serif;
  font-size: 9.067vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.15vw;
  text-align: center;

  @media (min-width: 769px) {
    color: #eaedee;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.014vw;
    font-size: 5vw;
  }
`;

export const DescriptionTextStyled = styled('p')`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.053vw;
  margin-bottom: 2.133vw;
  text-align: center;

  @media (min-width: 769px) {
    color: #eaedee;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.033vw;
    font-size: 2.083vw;
    line-height: 100%;
    white-space: nowrap;
    font-size: 2.5vw;
    margin-bottom: 0;
  }
`;

const dynamicIconsText = isMobile => css`
  ${isMobile
    ? css`
        color: rgba(255, 255, 255, 0.8);
        font-family: Inter, sans-serif;
        font-size: 4vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.053vw;
      `
    : css`
        color: #eaedee;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 1.25vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.944vw;
        letter-spacing: 0.014vw;
      `}
`;

export const IconsInfoTextStyled = styled.span`
  ${({ $isMobile }) => dynamicIconsText($isMobile)}
  margin-left: 2.4vw;
  @media (min-width: 769px) {
    margin-left: 0.8vw;
  }
`;

export const NetworkAdditionStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5.333vw;
  justify-content: center;
  @media (min-width: 769px) {
    margin-bottom: 0.972vw;
  }
`;

export const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: -1.867vw;
  }
  svg:nth-child(1) {
    z-index: 5;
  }
  svg:nth-child(2) {
    z-index: 4;
  }
  svg:nth-child(3) {
    z-index: 3;
  }
  svg:nth-child(4) {
    z-index: 2;
  }
  svg:nth-child(5) {
    z-index: 1;
  }
  @media (min-width: 769px) {
    justify-content: center;
    svg {
      margin-right: -0.556vw;
    }
  }
`;

export const ButtonStyled = styled.a`
  text-decoration: none;
  display: flex;
  width: fit-content;
  text-align: center;
  padding: 0 6.4vw;
  justify-content: center;
  align-items: center;
  background: linear-gradient(95deg, #b6e925 8.97%, #99c714 93.52%);
  color: #171d25;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.073vw;
  border: none;
  cursor: pointer;
  border-radius: 2.133vw;
  font-size: 4vw;

  @media (min-width: 769px) {
    font-size: 1.111vw;
    padding: 1.111vw 1.944vw;
    border-radius: 0.556vw;
    letter-spacing: 0.014vw;
  }
`;

export const StoreItem = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-color: #6a7484;
  border-width: 1px;
  border-style: solid;
  border-radius: 2.133vw;
  background-color: #000000;
  gap: 1.6vw;
`;

export const StoreText = styled.p`
  font-family: Inter, sans-serif;
  color: #eaedee;
  font-size: 3.2vw;
  white-space: nowrap;
  @media (min-width: 769px) {
    white-space: normal;
    font-size: 0.972vw;
    line-height: 1vw;
  }
`;

export const DownloadApkText = styled.a`
  color: rgba(151, 158, 170, 0.7);
  font-size: 3.733vw;
  font-family: Inter, sans-serif;
  text-decoration: underline;
  margin-top: 5.333vw;
  margin-bottom: 19.2vw;
`;
