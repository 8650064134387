import styled from 'styled-components';
import dappsImage from 'src/assets/img/dapps-desktop.webp';
import dappsTabletImage from 'src/assets/img/dapps-tablet.webp';

export const LeftSideWrapper = styled.div`
  @media (max-width: 1024px) {
    max-width: 60%;
  }
`;

export const Title = styled.p`
  font-size: clamp(0px, 2.222vw, 32px);
  font-weight: 700;
  color: #eaedee;
  font-family: Inter, sans-serif;
  margin-bottom: clamp(0px, 1.111vw, 16px);
  @media (max-width: 1024px) {
    font-size: 4.167vw;
  }
`;
export const InfoText = styled.p`
  color: #8f96a3;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  line-height: 140%;
  margin-bottom: clamp(0px, 2.222vw, 32px);
  @media (max-width: 1024px) {
    font-size: 2.083vw;
  }
`;
export const BoxStyled = styled.div`
  background-image: url(${dappsImage});
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  padding: clamp(0px, 5.278vw, 76px) 0;
  @media (max-width: 1024px) {
    background-image: url(${dappsTabletImage});
    background-size: 50%;
    background-position: right;
  }
`;
