import changeCryptoImage from 'src/assets/img/take-change-crypto.webp';
import { ImageStyled } from './styled';
import { Container } from 'src/components/Container/styled';

export const TakeChangeCryptoDesktop = () => {
  return (
    <Container id="Features">
      <ImageStyled alt="changeCryptoImage" src={changeCryptoImage} />
    </Container>
  );
};
