import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi-icon.svg';

import {
  ContentHaqabiStyled,
  ContentHaqabiTextStyled,
  ContentInfoStyled,
  ContentInfoTextStyled,
  ContentStyled,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DownloadAppButton } from '../DownloadAppButton';

export const HeaderDesktop = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return () => setElementId('');
  }, [elementId, navigate]);

  return (
    <Container $isMain={isMain}>
      <ContentStyled>
        <ContentHaqabiStyled onClick={() => navigate('/')}>
          <HaqabiIcon
            style={{
              width: 'clamp(0px,2.674vw, 38.5px)',
              height: 'clamp(0px,3.056vw, 44px)',
            }}
          />
          <ContentHaqabiTextStyled>Haqabi</ContentHaqabiTextStyled>
        </ContentHaqabiStyled>
        <ContentInfoStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Features')}>
            Features
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Comparison')}>
            Comparison
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Roadmap')}>
            Roadmap
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => setElementId('Documentation')}>
            Documentation
          </ContentInfoTextStyled>
          <ContentInfoTextStyled onClick={() => navigate('/airdrop')}>
            Airdrop
          </ContentInfoTextStyled>
        </ContentInfoStyled>
        <DownloadAppButton />
      </ContentStyled>
    </Container>
  );
};

const DownloadApkText = styled.p`
  font-size: 1.25vw;
  font-family: Inter, sans-serif;
  color: #c6cfd8;
`;
