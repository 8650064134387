import { Container } from 'src/components/Container/styled';
import { BoxStyled, InfoText, LeftSideWrapper, Title } from './styled';
import { ReactComponent as CreditCheck } from 'src/assets/icons/credit-card-check.svg';
export const BuyCrypto = () => {
  return (
    <Container style={{ padding: '0' }}>
      <BoxStyled>
        <LeftSideWrapper>
          <div
            style={{
              backgroundColor: '#1C2027',
              padding: '18px',
              borderRadius: '10px',
            }}>
            <CreditCheck />
          </div>
          <Title>Buy crypto with your local currency</Title>
          <InfoText>
            Start your crypto journey using your{' '}
            <span style={{ color: '#C4F538' }}>credit/debit card </span>
          </InfoText>
        </LeftSideWrapper>
      </BoxStyled>
    </Container>
  );
};
