import React, { useEffect, useState } from 'react';
import {
  BottomTextStyled,
  ContentInfoStyled,
  ContentSocialsStyled,
  ContentSocialsTextStyled,
  ContentStyled,
  InfoBlockStyled,
  InfoBlockTextStyled,
  InfoBlockTitleStyled,
  SocialLinkStyled,
  SocialsBlockStyled,
  SocialsIconBlockStyled,
} from './styled';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter-2.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram-2.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord-2.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn-2.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook-2.svg';
import { Container } from 'src/components/Container/styled';
import { useNavigate } from 'react-router-dom';

const socialIconSize = {
  width: 'clamp(0px, 1.389vw, 20px)',
  height: 'clamp(0px, 1.389vw, 20px)',
};

export const FooterDesktop = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);
  return (
    <Container>
      <ContentStyled>
        <ContentSocialsStyled>
          <SocialsIconBlockStyled onClick={() => window.scrollTo(0, 0)}>
            <HaqabiIcon
              width={'clamp(0px, 2.361vw, 34px)'}
              height={'clamp(0px, 2.361vw, 34px)'}
            />
            <ContentSocialsTextStyled>HAQABI</ContentSocialsTextStyled>
          </SocialsIconBlockStyled>
          <InfoBlockStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Features')}>
              Features
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Comparison')}>
              Comparison
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Roadmap')}>
              Roadmap
            </InfoBlockTitleStyled>
            <InfoBlockTitleStyled onClick={() => setElementId('Documentation')}>
              Documentation
            </InfoBlockTitleStyled>
          </InfoBlockStyled>
        </ContentSocialsStyled>
        <ContentInfoStyled>
          <SocialsBlockStyled>
            <SocialLinkStyled href="https://twitter.com/HaqabiHQ">
              <TwitterIcon {...socialIconSize} />
            </SocialLinkStyled>
            <SocialLinkStyled href="https://t.me/haqabi">
              <TelegramIcon color="white" {...socialIconSize} />
            </SocialLinkStyled>
            <SocialLinkStyled href="https://discord.gg/kXeefuKPmQ">
              <DiscordIcon {...socialIconSize} />
            </SocialLinkStyled>
            <SocialLinkStyled href="https://www.linkedin.com/company/haqabi/">
              <LinkedInIcon {...socialIconSize} />
            </SocialLinkStyled>
            <SocialLinkStyled href="https://www.facebook.com/haqabi.wallet">
              <FacebookIcon {...socialIconSize} />
            </SocialLinkStyled>
          </SocialsBlockStyled>
          <InfoBlockTextStyled>
            Contact us{' '}
            <span style={{ color: '#AADE16' }}>support@haqabi.com</span>
          </InfoBlockTextStyled>
        </ContentInfoStyled>
      </ContentStyled>
      <BottomTextStyled>
        Copyright © 2025. All rights reserved.
      </BottomTextStyled>
    </Container>
  );
};
