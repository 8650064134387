import { ReactComponent as AvalancheIcon } from 'src/assets/icons/avalanche.svg';
import { ReactComponent as XRPIcon } from 'src/assets/icons/xrp.svg';
import { ReactComponent as AtomIcon } from 'src/assets/icons/atom.svg';
import { ReactComponent as SolanaIcon } from 'src/assets/icons/solana.svg';
import { ReactComponent as BaseIcon } from 'src/assets/icons/base.svg';
import { ReactComponent as PolygonIcon } from 'src/assets/icons/polygon.svg';
import { ReactComponent as TonIcon } from 'src/assets/icons/ton.svg';
import { ReactComponent as ArbitrumIcon } from 'src/assets/icons/arbitrum.svg';
import { ReactComponent as FantomIcon } from 'src/assets/icons/fantom.svg';
import { ReactComponent as SuiIcon } from 'src/assets/icons/sui.svg';
import { ReactComponent as PolkadotIcon } from 'src/assets/icons/polkadot.svg';
import { ReactComponent as AlgorandIcon } from 'src/assets/icons/algorand.svg';
import { ReactComponent as NearIcon } from 'src/assets/icons/near.svg';
import { ReactComponent as CelestiaIcon } from 'src/assets/icons/celestia.svg';
import { ReactComponent as StellarIcon } from 'src/assets/icons/stellar.svg';
import { ReactComponent as AptosIcon } from 'src/assets/icons/apatos.svg';
import { ReactComponent as EthereumIcom } from 'src/assets/icons/ethereum-coin.svg';
import { ReactComponent as HaqqIcon } from 'src/assets/icons/haqq.svg';
import { ReactComponent as TronIcon } from 'src/assets/icons/tron-icon.svg';
import { ReactComponent as BnbIcon } from 'src/assets/icons/binance.svg';
import { ReactComponent as BtcIcon } from 'src/assets/icons/bitcoin-coin.svg';
import styled from 'styled-components';

const iconSize = { width: '5.333vw', height: '5.333vw' };

const HorizontalView = styled.div`
  display: flex;
  align-items: center;
  gap: 2.133vw;
`;

export const data = [
  {
    period: 'Q4',
    year: 2022,
    items: [
      {
        title: 'Defining the Vision and Mission',
      },
      {
        title: 'Team Assembly',
      },
    ],
  },
  {
    period: 'Q1',
    year: 2023,
    items: [
      {
        title: 'Research Markets',
      },
      {
        title: 'Research Competitors',
      },
      {
        title: 'Define Positioning',
      },
    ],
  },
  {
    period: 'Q2',
    year: 2023,
    items: [
      {
        title: 'Backend Development Started',
      },
      {
        title: 'Architecture Development',
      },
      {
        title: 'Wallet Creation & Registration',
      },
    ],
  },
  {
    period: 'Q3',
    year: 2023,
    items: [
      {
        title: 'Seedless Recovery',
      },
      {
        title: 'Mobile Frontend Development started',
      },
      {
        title: 'Ethereum Network',
        icon: <EthereumIcom {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q4',
    year: 2023,
    items: [
      {
        title: 'HAQQ Network',
        icon: <HaqqIcon {...iconSize} />,
      },
      {
        title: 'TRON Network',
        icon: <TronIcon {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q1',
    year: 2022,
    items: [
      {
        title: 'On-chain Swaps',
      },
      {
        title: 'Bitcoin Network',
        icon: <BtcIcon {...iconSize} />,
      },
      {
        title: 'BNB Network',
        icon: <BnbIcon {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q2',
    year: 2024,
    items: [
      {
        title: 'Wallet Launch',
      },
      {
        title: 'Cross-chain Swaps & On-ramp',
      },
      {
        title: 'Solana Network',
        icon: <SolanaIcon {...iconSize} />,
      },
      {
        title: 'Cosmos Network',
        icon: <AtomIcon {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q3',
    year: 2024,
    items: [
      {
        title: 'Wallet Connect',
      },
      {
        title: 'dApps',
      },
      {
        title: 'Asset Management',
      },
      {
        title: 'Networks support',
        icon: (
          <HorizontalView>
            <XRPIcon {...iconSize} />
            <AvalancheIcon {...iconSize} />
            <BaseIcon {...iconSize} />
            <PolygonIcon {...iconSize} />
          </HorizontalView>
        ),
      },
    ],
  },
  {
    period: 'Q4',
    year: 2024,
    items: [
      {
        title: 'Staking of TRON, ETH, BNB',
      },
      {
        title: 'Bonus Program',
      },
      {
        title: 'Portfolio',
      },
      {
        title: 'Networks support',
        icon: (
          <HorizontalView>
            <TonIcon {...iconSize} />
            <ArbitrumIcon {...iconSize} />
            <FantomIcon {...iconSize} />
          </HorizontalView>
        ),
      },
    ],
  },
  {
    period: 'Q1',
    year: 2025,
    items: [
      {
        title: 'Staking Stablecoins',
      },
      {
        title: 'Gas Free Transactions',
      },
      {
        title: 'Localization',
      },
      {
        title: 'Networks support',
        icon: (
          <HorizontalView>
            <NearIcon {...iconSize} />
            <PolkadotIcon {...iconSize} />
          </HorizontalView>
        ),
      },
    ],
  },
  {
    period: 'Q2',
    year: 2025,
    items: [
      {
        title: 'Tokenized Assets & NFT',
      },
      {
        title: 'Bonus Program',
      },
      {
        title: 'Portfolio',
      },
      {
        title: 'Aptos Network',
        icon: <AptosIcon {...iconSize} />,
      },
    ],
  },
  {
    period: 'Q3',
    year: 2025,
    items: [
      {
        title: 'AI Portfolio Strategy',
      },
      {
        title: 'Networks support',
        icon: (
          <HorizontalView>
            <StellarIcon {...iconSize} />
            <SuiIcon {...iconSize} />
            <CelestiaIcon {...iconSize} />
            <AlgorandIcon {...iconSize} />
          </HorizontalView>
        ),
      },
    ],
  },
  {
    period: 'Q4',
    year: 2025,
    items: [
      {
        title: 'Adding Other Networks',
      },
      {
        title: 'Merchant Service',
      },
      {
        title: 'P2P by Haqqex',
      },
      {
        title: 'Whitelabel',
      },
    ],
  },
];
