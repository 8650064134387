import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import images from './util/images';
import s from './index.module.scss';
import { fetchData } from 'src/butter-client';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { Button, Text } from 'src/pages/CMS/components';
import Image from 'src/pages/CMS/components/Image';
import { Article } from './components/Article';
import Loader from 'src/pages/CMS/components/Loader';
import { Container } from '../../../../components/Container/styled';
import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
  height: 0.267vw;
  background: linear-gradient(#c6cfd866, #c6cfd8, #c6cfd866);
  opacity: 0.2;
  margin: 8vw 0;

  @media (min-width: 769px) {
    height: 0.069vw;
    margin: 8.472vw 0;
  }
`;

export const Education = () => {
  const { md, lg } = useBreakpoint();
  const [showLoader, setShowLoader] = useState(true);
  const [swiper, setSwiper] = useState();
  const [articles, setArticles] = useState([]);
  const [currSlide, setCurrSlide] = useState(swiper?.activeIndex || 0);

  const next = useCallback(() => {
    swiper && swiper.slideNext();
  }, [swiper]);

  const prev = useCallback(() => {
    swiper && swiper.slidePrev();
  }, [swiper]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchData('posts', {
          category_slug: 'haqabi',
        });
        setArticles(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setShowLoader(false);
      }
    })();
  }, []);

  return (
    <Container id="Education">
      <div className={cx(s.col, 'relative z-1 mt-200 mx-auto slowBlock')}>
        <div className="flex items-center justify-between">
          <Text size={md ? 'b32' : 'b24'} message={'Education'} />
          <div className="flex items-center grid-gap-12">
            <button type="button" onClick={prev} disabled={currSlide === 0}>
              <Image
                size={32}
                src={images.arrowLeft}
                color={currSlide === 0 ? 'neutral-6' : 'neutral-1'}
                className={currSlide === 0 ? '' : cx(s.arrow, 'transition')}
              />
            </button>
            <button type="button" onClick={next} disabled={swiper?.isEnd}>
              <Image
                size={32}
                src={images.arrowRight}
                color={swiper?.isEnd ? 'neutral-6' : 'neutral-1'}
                className={swiper?.isEnd ? s.arrow : cx(s.arrow, 'transition')}
              />
            </button>
          </div>
        </div>
        {showLoader ? (
          <Loader small />
        ) : (
          <div className="relative mt-40">
            <Swiper
              style={
                lg
                  ? { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }
                  : md
                    ? { display: 'grid', gridTemplateColumns: '1fr 1fr' }
                    : { display: 'grid', gridTemplateColumns: '1fr' }
              }
              className={cx(
                'grid',
                lg ? 'grid-col-3' : md ? 'grid-col-2' : 'grid-col-1',
              )}
              onSwiper={setSwiper}
              onSlideChange={swiper => setCurrSlide(swiper.activeIndex)}
              spaceBetween={20}
              slidesPerView={lg ? 3 : md ? 2 : 1}
              grabCursor>
              {articles.slice(0, 6).map(item => (
                <SwiperSlide key={item.slug} className="h-auto">
                  <Article
                    url={`/education${item.url}`}
                    title={item.title}
                    description={item.summary}
                    image={item.featured_image}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        <a href={`/education`} className="mt-40 inline-block">
          <Button
            size="xl"
            bg="neutral"
            message={'Show All'}
            className="px-48"
          />
        </a>
      </div>
    </Container>
  );
};
