import styled, { css } from 'styled-components';

export const MainStyled = styled.div`
  margin-top: clamp(0px, 8.533vw, 122.875px);
  display: flex;
  text-align: center;
  flex-direction: column;
`;

export const TextStyled = styled('p')`
  color: var(--color-primary-5);
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 8.533vw;
  letter-spacing: 0.073vw;

  @media (min-width: 769px) {
    line-height: 100%;
    font-size: 1.25vw;
    letter-spacing: 0;
  }
`;

export const MainTextStyled = styled('p')`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 6.25vw, 90px);
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;

  @media (max-width: 1024px) {
    font-size: 10.417vw;
  }
`;

export const DescriptionTextStyled = styled('p')`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.083vw, 30px);
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: clamp(0px, 0.053vw, 0.763px);
  margin-bottom: clamp(0px, 2.133vw, 30px);
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 3.125vw;
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 4.167vw;
  }
`;

export const IconsInfoTextStyled = styled.span`
  color: rgba(151, 158, 170, 0.7);
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  margin-left: 10px;
  font-size: 16px;
`;

export const NetworkAdditionStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: clamp(0px, 5.333vw, 76.795px);
  justify-content: center;
`;

export const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: -1.867vw;
  }
  svg:nth-child(1) {
    z-index: 5;
  }
  svg:nth-child(2) {
    z-index: 4;
  }
  svg:nth-child(3) {
    z-index: 3;
  }
  svg:nth-child(4) {
    z-index: 2;
  }
  svg:nth-child(5) {
    z-index: 1;
  }
  @media (min-width: 769px) {
    justify-content: center;
    svg {
      margin-right: -0.556vw;
    }
  }
`;

export const ButtonStyled = styled.a`
  text-decoration: none;
  display: flex;
  width: fit-content;
  text-align: center;
  padding: 0 6.4vw;
  justify-content: center;
  align-items: center;
  background: linear-gradient(95deg, #b6e925 8.97%, #99c714 93.52%);
  color: #171d25;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.073vw;
  border: none;
  cursor: pointer;
  border-radius: clamp(0px, 2.133vw, 30px);
  font-size: 4vw;

  @media (min-width: 769px) {
    font-size: 1.111vw;
    padding: 1.111vw 1.944vw;
    border-radius: 0.556vw;
    letter-spacing: 0.014vw;
  }
`;

export const StoreItem = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid rgba(149, 153, 158, 0.9);
  background-color: #000;
  border-radius: clamp(0px, 2.133vw, 30px);
  gap: clamp(0px, 5.333, 76px);
  padding: 0;
  border-radius: clamp(0px, 0.556vw, 8px);
  gap: clamp(0px, 1.389vw, 20px);
  height: clamp(0px, 3.611vw, 52px);
`;

export const StoreText = styled.p`
  font-family: Inter, sans-serif;
  color: #eaedee;
  font-size: 3.2vw;
  @media (min-width: 769px) {
    font-size: 0.972vw;
    line-height: 1vw;
  }
`;

export const StoreItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: clamp(0px, 0.833vw, 12px);
`;

export const AndroidApkLink = styled.button`
  color: rgba(151, 158, 170, 0.7);
  font-size: 14px;
  font-family: Inter, sans-serif;
  text-decoration: underline;
  margin-top: 16px;
`;
