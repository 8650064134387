import styled from 'styled-components';

export const Title = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 6.4vw;
  margin: 8.533vw 0 4.267vw;
  font-weight: 700;
`;

export const InfoText = styled.p`
  color: #8f96a3;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 7.467vw;
`;

export const BoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13.867vw 0;
`;
