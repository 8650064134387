import { Container } from 'src/components/Container/styled';
import { useState } from 'react';
import iphoneMockupImage from 'src/assets/img/iphone-mockup-desktop.webp';
import {
  ContentDescriptionStyled,
  ContentStyled,
  ContentTitleStyled,
  ContentWrapperStyled,
  ImageStyled,
  ContentContainerStyled,
  BoxStyled,
  LogoWrapper,
  RightSideWrapper,
  Title,
  DescriptionText,
  LearnMoreText,
  ImgageStyled,
} from './styled';

import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import signalredLogo from 'src/assets/img/singal-red-logo.webp';

export const SignalRed = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <BoxStyled>
        <LogoWrapper>
          <ImgageStyled src={signalredLogo} />
        </LogoWrapper>
        <RightSideWrapper>
          <Title>Cybersecurity milestone</Title>
          <DescriptionText>
            {' '}
            Haqabi passed penetration test with
            <span style={{ color: '#fff' }}> Signal Red</span>
          </DescriptionText>
          <LearnMoreText
            onClick={() =>
              navigate(
                '/education/announcing-haqabi-partnership-with-signalred',
              )
            }>
            Learn more
          </LearnMoreText>
        </RightSideWrapper>
      </BoxStyled>
    </Container>
  );
};
