import styled from 'styled-components';

export const BoxStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    text-align: center;
    flex-direction: column;
  }
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 36.111vw;
  align-items: flex-start;
  @media (max-width: 1024px) {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

export const Title = styled.p`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.25vw, 18px);
  font-style: normal;
  font-weight: 500;
  margin-bottom: clamp(0px, 0.556vw, 8px);

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 2.344vw;
  }
`;

export const ConcernsText = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.222vw, 32px);
  font-style: normal;
  font-weight: 700;
  margin-bottom: clamp(0px, 2.222vw, 32px);
  white-space: nowrap;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 4.167vw;
  }
`;

export const SecurityTitle = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.25vw, 18px);
  font-style: normal;
  font-weight: 600;
  margin-bottom: clamp(0px, 0.556vw, 8px);
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 2.344vw;
  }
`;

export const DescriptionText = styled.p`
  color: #8f96a3;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  font-style: normal;
  line-height: 140%;
  margin-bottom: 2.222vw;
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 2.083vw;
  }
`;

export const ImageStyled = styled.img`
  width: 480px;
`;
