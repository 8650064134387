import { useDeviceType } from 'src/hooks/useDeviceType';
import { TitleStyled } from './styled';
import { Container } from 'src/components/Container/styled';
import { VerticalView } from 'src/components/FlexViews';
import comparisonDesktop from 'src/assets/img/comparison-desktop.webp';
import comparisonMobile from 'src/assets/img/comparison-mobile.webp';

export const Difference = ({ device }) => {
  const { isMobile } = useDeviceType();
  return (
    <Container
      id="Comparison"
      style={{ marginTop: device.isMobile ? '6.4vw' : '' }}>
      <VerticalView>
        <TitleStyled>Features setting us apart</TitleStyled>
        <img
          src={isMobile ? comparisonMobile : comparisonDesktop}
          width={'100%'}
        />
      </VerticalView>
    </Container>
  );
};
