import { Container } from 'src/components/Container/styled';
import treeGame from 'src/assets/img/tree-game.webp';
import { DownloadAppButton } from '../DownloadAppButton';
import {
  BoxStyled,
  GameInfoItem,
  GameInfoItemDescription,
  GameInfoItemTitle,
  GameInfoList,
  GameName,
  Title,
} from './styled';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';

export const TreeGame = () => {
  return (
    <BoxStyled>
      <img src={treeGame} width={'30%'} />
      <Title>Play, Earn, and Compete in </Title>
      <GameName>Haqabi Tree Game!</GameName>
      <GameInfoList>
        <GameInfoItem>
          <GameInfoItemTitle>
            Daily Coins:{' '}
            <GameInfoItemDescription>
              play and climb the leaderboard.
            </GameInfoItemDescription>
          </GameInfoItemTitle>
        </GameInfoItem>
        <GameInfoItem>
          <GameInfoItemTitle>
            Boost Your Earnings:{' '}
            <GameInfoItemDescription>
              unlock earning multipliers by maintaining a balance over $100.
            </GameInfoItemDescription>
          </GameInfoItemTitle>
        </GameInfoItem>
        <GameInfoItem>
          <GameInfoItemTitle>
            Seasonal Prizes:{' '}
            <GameInfoItemDescription>
              compete to win valuable prizes every season.
            </GameInfoItemDescription>
          </GameInfoItemTitle>
        </GameInfoItem>
      </GameInfoList>
      <VerticalView>
        <DownloadAppButton />
      </VerticalView>
    </BoxStyled>
  );
};
