import { Container } from 'src/components/Container/styled';
import {
  BoxStyled,
  DescriptionText,
  LeftSideWrapper,
  PlayMarketText,
  RareScore,
  StoreItem,
  StoresView,
  Title,
} from './styled';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as DownloadOnPlayMarket } from 'src/assets/icons/download-on-google-play.svg';
import { ReactComponent as DownloadOnAppStore } from 'src/assets/icons/download-on-app-store.svg';
import { ReactComponent as DownloadWithApk } from 'src/assets/icons/download-on-apk.svg';
import { ReactComponent as DownloadWithApkMobile } from 'src/assets/icons/download-on-apk-mobile.svg';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { ReactComponent as Star3Icon } from 'src/assets/icons/star-3.svg';
import { useNavigate } from 'react-router-dom';

export const StoresInfo = ({ device }) => {
  const { isMobile } = device;
  const navigate = useNavigate();

  return (
    <Container id="DownloadApp" style={{ padding: '0' }}>
      <BoxStyled>
        <LeftSideWrapper>
          <HorizontalView style={{ gap: '8px' }}>
            <PlayMarketIcon
              width={isMobile ? '4.267vw' : 'clamp(0px, 1.111vw, 16px)'}
              height={isMobile ? '4.267vw' : 'clamp(0px, 1.111vw, 16px)'}
            />
            <PlayMarketText>Google Play</PlayMarketText>
            <RareScore>4.92</RareScore>
            <HorizontalView>
              <Star3Icon />
              <Star3Icon />
              <Star3Icon />
              <Star3Icon />
              <Star3Icon />
            </HorizontalView>
          </HorizontalView>
          <Title>Download Haqabi Wallet 🚀</Title>
          <DescriptionText>
            Our app is available on Google Play and App Store.{' '}
            {!isMobile && <br />} Download it today for easy access to your
            crypto assets!
          </DescriptionText>
          <StoresView style={{ gap: 'clamp(0px, 0.833vw, 12px)' }}>
            <StoreItem
              href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
              target="_blank">
              <DownloadOnAppStore
                width={isMobile ? '49.333vw' : 'clamp(0px, 12.847vw, 185px)'}
                height={isMobile ? '13.867vw' : 'clamp(0px, 3.611vw, 52px)'}
              />
            </StoreItem>
            <StoreItem
              href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
              target="_blank">
              <DownloadOnPlayMarket
                width={isMobile ? '49.333vw' : 'clamp(0px, 12.847vw, 185px)'}
                height={isMobile ? '13.867vw' : 'clamp(0px, 3.611vw, 52px)'}
              />
            </StoreItem>

            <StoreItem
              isMobile={isMobile}
              onClick={() => navigate('/download-apk')}>
              {isMobile ? (
                <DownloadWithApkMobile width={'40.8vw'} height="8.533vw" />
              ) : (
                <DownloadWithApk
                  width={'clamp(0px, 10.972vw, 158px)'}
                  height={'clamp(0px, 3.611vw, 52px)'}
                />
              )}
            </StoreItem>
          </StoresView>
        </LeftSideWrapper>
      </BoxStyled>
    </Container>
  );
};
