import haqabiDesktopBackground from 'src/assets/img/haqabi-bg-desktop.png';
import haqabiBackground from 'src/assets/img/haqabi-bg.png';
import styled, { css } from 'styled-components';

const getIsMainWrapper = isMain => {
  return isMain
    ? css`
        background-image: url(${haqabiDesktopBackground});
        background-size: 90%;
        background-position: top;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        gap: clamp(0px, 5.556vw, 80px);
        @media (max-width: 768px) {
          background-image: url(${haqabiBackground});
          padding: 1.111vw 0 16vw;
          background-size: 100%;
        }
      `
    : css`
        width: 100%;
        height: 100%;
        background-color: #191d22;
        min-height: 100vh;
        padding: 1.111vw 0 16vw;
        @media (min-width: 769px) {
          background-color: #090d12;
        }
      `;
};

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: ${props => (props.$isMain ? '0 4.267vw' : '0 5.333vw')};
  @media (min-width: 769px) {
    padding: ${props => (props.$isMain ? '2.778vw 6.944vw' : '0 6.944vw')};
  }
  @media (min-width: 1440px) {
    padding: ${props => (props.$isMain ? '0 60px' : '0 76px')};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: clamp(0px, 2.778vw, 40px) 0 clamp(0px, 6.25vw, 90px);
  ${props => getIsMainWrapper(props.$isMain)}
`;
