import styled from 'styled-components';

export const ContentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHaqabiStyled = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(0px, 0.677vw, 10px);
  cursor: pointer;
`;
export const ContentHaqabiTextStyled = styled.span`
  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.05vw;
  text-transform: uppercase;
  letter-spacing: clamp(0px, 0.037vw, 0.533px);
  font-size: clamp(0px, 1.25vw, 18px);
`;

export const ContentInfoStyled = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(0px, 1.944vw, 28px);
`;
export const ContentInfoTextStyled = styled.a`
  color: #eaedee;
  text-align: center;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: clamp(0px, 1.944vw, 28px);
  font-size: clamp(0px, 1.25vw, 18px);
  letter-spacing: clamp(0px, 0.014vw, 0.202px);
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ContactsIconsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.556vw;
`;

export const ContactsButtonStyled = styled.a`
  text-decoration: none;
  border: 0.069vw solid #b3e624;

  color: #a0cf18;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.389vw, 20px);
  font-style: normal;
  font-weight: 500;
  line-height: clamp(0px, 2.292vw, 33px);
  letter-spacing: 0.019vw;
  letter-spacing: 0.019vw;
  background-color: transparent;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #b3e624;
    color: #171d25;
  }

  border-radius: 0.556vw;
  font-size: 0.972vw;
  padding: 0.556vw 1.944vw;
  margin-left: 1.111vw;
`;
export const ButtonStyled = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;
`;

export const StoreItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.833vw;
  padding: 0.556vw 0.833vw;
  border-radius: 0.556vw;
  background: rgba(51, 59, 72, 0.8);
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
