import { Container } from 'src/components/Container/styled';
import { DownloadAppButton } from '../DownloadAppButton';
import treeGame from 'src/assets/img/tree-game.webp';
import {
  BoxStyled,
  GameInfoItem,
  GameInfoItemDescription,
  GameInfoItemTitle,
  GameInfoList,
  GameName,
  LeftSideWrapper,
  Title,
} from './styled';

export const TreeGameDesktop = () => {
  return (
    <Container style={{ padding: '0' }}>
      <BoxStyled>
        <LeftSideWrapper>
          <Title>Play, Earn, and Compete in </Title>
          <GameName>Haqabi Tree Game!</GameName>
          <GameInfoList>
            <GameInfoItem>
              <GameInfoItemTitle>
                Daily Coins:{' '}
                <GameInfoItemDescription>
                  play and climb the leaderboard.
                </GameInfoItemDescription>
              </GameInfoItemTitle>
            </GameInfoItem>
            <GameInfoItem>
              <GameInfoItemTitle>
                Boost Your Earnings:{' '}
                <GameInfoItemDescription>
                  unlock earning multipliers by maintaining a balance over $100.
                </GameInfoItemDescription>
              </GameInfoItemTitle>
            </GameInfoItem>
            <GameInfoItem>
              <GameInfoItemTitle>
                Seasonal Prizes:{' '}
                <GameInfoItemDescription>
                  compete to win valuable prizes every season.
                </GameInfoItemDescription>
              </GameInfoItemTitle>
            </GameInfoItem>
          </GameInfoList>
          <DownloadAppButton />
        </LeftSideWrapper>
        <img src={treeGame} width={'40%'} />
      </BoxStyled>
    </Container>
  );
};
