import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDeviceType } from 'src/hooks/useDeviceType';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { Wrapper } from 'src/components/Container/styled';
import { Header } from './components/Header';
import { HeaderDesktop } from './components/HeaderDesktop';
import { AboutUs } from './components/AboutUs';
import { AboutUsDesktop } from './components/AboutUsDesktop';
import { TakeChangeCrypto } from './components/Features';
import { TakeChangeCryptoDesktop } from './components/FeaturesDesktop';
import { ShamirSharing } from './components/Swaps';
import { ShamirSharingDesktop } from './components/SwapsDesktop';
import { BuyCrypto } from './components/BuyCrypto';
import { Dapps } from './components/Dapps';
import { DappsDesktop } from './components/DappsDesktop';
import { Difference } from './components/Difference';
import { AppInfo } from './components/AppInfo';
import { TreeGame } from './components/TreeGame';
import { TreeGameDesktop } from './components/TreeGameDesktop';
import { StoresInfo } from './components/StoresInfo';
import { Footer } from './components/Footer';
import { FooterDesktop } from './components/FooterDesktop';
import { ProjectTimeline } from './components/ProjectTimeline';
import { ProjectTimelineDesktoop } from './components/ProjectTimelineDesktoop';
import { SignalRed } from './components/SignalRed';
import { WorldClassSolutions } from './components/WorldClassSolutions';
import { Documentation } from './components/Documentation';
import { Education } from './components/Education';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const MainPage = () => {
  const { state } = useLocation();
  const elementId = state?.elementId;
  const device = useDeviceType();
  const { isMobile, isDesktop } = device;

  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Wrapper $isMain>
      {isDesktop ? <HeaderDesktop isMain /> : <Header device={device} />}
      {isMobile ? <AboutUs /> : <AboutUsDesktop />}
      {isMobile ? <TakeChangeCrypto /> : <TakeChangeCryptoDesktop />}
      {isMobile ? <ShamirSharing /> : <ShamirSharingDesktop device={device} />}
      <BuyCrypto />
      {isMobile ? <Dapps /> : <DappsDesktop />}
      {isMobile ? <TreeGame /> : <TreeGameDesktop />}
      <Difference device={device} />
      <AppInfo device={device} />
      <StoresInfo device={device} />
      {isMobile ? (
        <ProjectTimeline />
      ) : (
        <ProjectTimelineDesktoop device={device} />
      )}
      <SignalRed />
      <WorldClassSolutions device={device} />
      <Documentation device={device} />
      <Education />
      {isMobile ? <Footer /> : <FooterDesktop />}
    </Wrapper>
  );
};
