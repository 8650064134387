import { Container } from 'src/components/Container/styled';
import dappsImage from 'src/assets/img/dapps.webp';
import { InfoText, Title, BoxStyled } from './styled';
import { DownloadAppButton } from '../DownloadAppButton';

export const Dapps = () => {
  return (
    <Container style={{ marginTop: '17.067vw' }}>
      <BoxStyled>
        <img src={dappsImage} style={{ width: '100%' }} />
        <Title>Connect with DApps through your wallet!</Title>
        <InfoText>
          We work with such Dapps as 1inch, SushiSwap, Uniswap, Aave and{' '}
          <span style={{ color: '#C4F538' }}>many others</span>
        </InfoText>
        <DownloadAppButton />
      </BoxStyled>
    </Container>
  );
};
