import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
export const BoxStyled = styled(HorizontalView)`
  background: linear-gradient(90deg, #12161b 0%, #282c23 87.36%);
  justify-content: space-between;
`;
export const LeftSideWrapper = styled(VerticalView)`
  align-items: flex-start;
  padding: 5.556vw 0 5.556vw 6.944vw;
  @media (min-width: 1440px) {
    padding: 80px 0 80px 100px;
  }
`;
export const Title = styled.p`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: clamp(0px, 1.25vw, 18px);
  margin-bottom: 0.556vw;
  @media (max-width: 1024px) {
    font-size: 2.344vw;
    margin-bottom: 1.042vw;
  }
`;
export const GameName = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.2222vw, 32px);
  font-weight: 700;
  margin-bottom: clamp(0px, 1.667vw, 24px);
  @media (max-width: 1024px) {
    font-size: 4.167vw;
    margin-bottom: 3.125vw;
  }
`;
export const GameInfoList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: clamp(0px, 0.417vw, 6px);
  margin-bottom: clamp(0px, 5.556vw, 80px);
  @media (max-width: 1024px) {
    gap: 2.083vw;
    margin-bottom: 7.292vw;
  }
`;
export const GameInfoItem = styled.li`
  position: relative;

  @media (min-width: 768px) {
    left: 1.563vw;
  }

  @media (min-width: 1024px) {
    left: 0.903vw;
  }
  @media (min-width: 1440px) {
    left: 13px;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(151, 158, 170, 0.5);
    width: 6px;
    height: 6px;
    left: -1.563vw;
    top: 0.391vw;

    @media (min-width: 768px) {
      left: -1.563vw;
      top: 1.102vw;
    }

    @media (min-width: 1024px) {
      left: -0.903vw;
      top: 0.5vw;
    }

    @media (min-width: 1440px) {
      top: 8px;
      left: -13px;
    }
  }
`;
export const GameInfoItemTitle = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  line-height: 140%;
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 2.083vw;
  }
`;
export const GameInfoItemDescription = styled.span`
  color: #979eaa;
  font-weight: 400;
  line-height: 140%;
`;
