import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  CardItem,
  ItemStyled,
  PeriodStyled,
  TitleStyled,
  YearStyled,
  ProjectTimeLineWrapper,
} from './styled';

import { Container } from 'src/components/Container/styled';
import { data } from './data';

export const ProjectTimeline = () => {
  return (
    <Container id="Roadmap">
      <ProjectTimeLineWrapper>
        <TitleStyled>Project timeline</TitleStyled>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}>
          {data.map((timeline, index) => (
            <SwiperSlide key={index}>
              <CardItem Item>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2.133vw',
                  }}>
                  <PeriodStyled>{timeline.period}</PeriodStyled>
                  <YearStyled>{timeline.year}</YearStyled>
                </div>
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2.133vw',
                  }}>
                  {timeline.items.map((item, itemIdx) => {
                    return (
                      <div
                        key={itemIdx}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '2.133vw',
                        }}>
                        <ItemStyled>{item.title}</ItemStyled>
                        {item.icon}
                      </div>
                    );
                  })}
                </ul>
              </CardItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </ProjectTimeLineWrapper>
    </Container>
  );
};
