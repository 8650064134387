import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled, { css } from 'styled-components';

export const TitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.222vw, 32px);
  font-weight: 700;
  margin-top: 20px;

  @media (max-width: 1024px) {
    font-size: 4.167vw;
  }
`;

export const RoadmapYearText = styled.p`
  color: var(--Neutral-11, #171d25);
  font-family: Inter;
  font-size: clamp(0px, 1.111vw, 16px);
  font-weight: 700;
  line-height: 150%;
  background-color: #c4f538;
  padding: clamp(0px, 0.278vw, 4px) clamp(0px, 1.389vw, 20px);
  border-radius: clamp(0px, 0.556vw, 8px);

  @media (max-width: 1024px) {
    font-size: 2.083vw;
    padding: 0.521vw 2.083vw;
  }
`;

export const YearAchivementsWrapper = styled(HorizontalView)`
  align-items: flex-start;
  gap: clamp(0px, 1.667vw, 24px);
  padding: clamp(0px, 1.944vw, 28px) 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
export const SeasonAchivementsWrapper = styled(HorizontalView)`
  align-items: flex-start;
  gap: clamp(0px, 0.833vw, 12px);
`;
export const SeasonsAchivementsWrapper = styled(VerticalView)`
  gap: clamp(0px, 0.833vw, 12px);
`;
export const SeasonText = styled.p`
  color: #eaedee;
  font-family: Inter;
  font-size: clamp(0px, 1.25vw, 18px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 2.344vw;
  }
`;
export const AchivementTextStyled = styled.p`
  color: rgba(233, 237, 243, 0.9);
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.032px;
  @media (max-width: 1024px) {
    font-size: 2.083vw;
  }
`;
export const AchivementWrapper = styled(HorizontalView)`
  border-radius: clamp(0px, 0.833vw, 12px);
  background: #171b21;
  gap: clamp(0px, 0.7vw, 10px);
  padding: clamp(0px, 0.278vw, 4px) clamp(0px, 0.972vw, 14px);
  @media (max-width: 1024px) {
    padding: 0.521vw 1.823vw;
    border-radius: 1.563vw;
  }
`;

export const AchivementsWrapper = styled(HorizontalView)`
  gap: clamp(0px, 0.833vw, 12px);
  flex-wrap: wrap;
`;
