import styled from 'styled-components';

export const BoxStyled = styled.div`
  color: #eaedee;
`;

export const TextTypographyStyled = styled.p`
  color: #eaedee;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 6.4vw;
  margin-top: 20px;
`;

export const TitleStyled = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-size: 4.533vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
`;

export const ContentBoxStyled = styled.ul`
  display: flex;
  gap: 2.133vw;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
`;

export const FeatureItemStyled = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3.2vw;
  border-radius: 3.2vw;
  background-color: #1a1e24;
  padding: 3.2vw;
`;
export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AccordionBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #e4eaec;
  font-family: Inter, sans-serif;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.053vw;
  padding: 4.267vw 0 0 11.733vw;
`;

export const ArrowIconStyled = styled.div`
  color: transparent;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const AccordionCollapse = styled.div`
  height: ${({ $isOpen }) => ($isOpen ? 'min-content' : '0')};
  overflow: hidden;
  transition: height 0.3s;
`;
