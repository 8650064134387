import { ReactComponent as Icons } from 'src/assets/icons/icons.svg';
import {
  AndroidApkLink,
  DescriptionTextStyled,
  IconsInfoTextStyled,
  MainStyled,
  MainTextStyled,
  NetworkAdditionStyled,
  StoreItem,
  StoreItemsWrapper,
  StoreText,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { ReactComponent as DownloadOnGoogleMarket } from 'src/assets/icons/download-on-google-play.svg';
import { ReactComponent as DownloadOnAppStore } from 'src/assets/icons/download-on-app-store.svg';
import { useNavigate } from 'react-router-dom';

export const AboutUsDesktop = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <MainStyled>
        <MainTextStyled>Haqabi Wallet</MainTextStyled>
        <DescriptionTextStyled>
          Secure, simple and ethical crypto management
        </DescriptionTextStyled>
        <NetworkAdditionStyled>
          <Icons width={'164px'} height="36px" />
          <IconsInfoTextStyled $isMobile={false}>and more</IconsInfoTextStyled>
        </NetworkAdditionStyled>

        <StoreItemsWrapper>
          <StoreItem
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank">
            <DownloadOnAppStore width={'clamp(0px, 12.778vw, 184px)'} />
          </StoreItem>
          <StoreItem
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank">
            <DownloadOnGoogleMarket width={'clamp(0px, 12.5vw, 180px)'} />
          </StoreItem>
        </StoreItemsWrapper>
        <AndroidApkLink onClick={() => navigate('/download-apk')}>
          Also available in Android APK
        </AndroidApkLink>
      </MainStyled>
    </Container>
  );
};
