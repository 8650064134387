import { ReactComponent as EthereumIcom } from 'src/assets/icons/ethereum-coin.svg';
import { ReactComponent as HaqqIcon } from 'src/assets/icons/haqq.svg';
import { ReactComponent as TronIcon } from 'src/assets/icons/tron-icon.svg';
import { ReactComponent as BnbIcon } from 'src/assets/icons/binance.svg';
import { ReactComponent as BtcIcon } from 'src/assets/icons/bitcoin-coin.svg';
import { ReactComponent as FlagIcon } from 'src/assets/icons/flag.svg';
import { ReactComponent as AvalancheIcon } from 'src/assets/icons/avalanche.svg';
import { ReactComponent as XRPIcon } from 'src/assets/icons/xrp.svg';
import { ReactComponent as AtomIcon } from 'src/assets/icons/atom.svg';
import { ReactComponent as SolanaIcon } from 'src/assets/icons/solana.svg';
import { ReactComponent as BaseIcon } from 'src/assets/icons/base.svg';
import { ReactComponent as PolygonIcon } from 'src/assets/icons/polygon.svg';
import { ReactComponent as TonIcon } from 'src/assets/icons/ton.svg';
import { ReactComponent as ArbitrumIcon } from 'src/assets/icons/arbitrum.svg';
import { ReactComponent as FantomIcon } from 'src/assets/icons/fantom.svg';
import { ReactComponent as SuiIcon } from 'src/assets/icons/sui.svg';
import { ReactComponent as PolkadotIcon } from 'src/assets/icons/polkadot.svg';
import { ReactComponent as AlgorandIcon } from 'src/assets/icons/algorand.svg';
import { ReactComponent as NearIcon } from 'src/assets/icons/near.svg';
import { ReactComponent as CelestiaIcon } from 'src/assets/icons/celestia.svg';
import { ReactComponent as StellarIcon } from 'src/assets/icons/stellar.svg';
import { ReactComponent as AptosIcon } from 'src/assets/icons/apatos.svg';

const iconSize = {
  width: 'clamp(0px, 1.389vw, 20px)',
  height: 'clamp(0px, 1.389vw, 20px)',
};

export const data = [
  {
    year: '2022',
    seasons: [
      {
        season: 'q4',
        achivements: [
          { title: 'Defining the Vision and Mission' },
          { title: 'Team Assembly' },
        ],
      },
    ],
  },
  {
    year: '2023',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'Research Markets' },
          { title: 'Research Competitors' },
          { title: 'Define Positioning' },
        ],
      },
      {
        season: 'q2',
        achivements: [
          { title: 'Backend Development Started' },
          { title: 'Architecture Development' },
          { title: 'Wallet Creation & Registration' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'Seedless Recovery' },
          { title: 'Mobile Frontend Development started' },
          {
            title: 'Ethereum Network',
            leftIcon: <EthereumIcom {...iconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'HAQQ Network', leftIcon: <HaqqIcon {...iconSize} /> },
          { title: 'TRON Network', leftIcon: <TronIcon {...iconSize} /> },
        ],
      },
    ],
  },
  {
    year: '2024',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'On-chain Swaps' },
          { title: 'Bitcoin Network', leftIcon: <BtcIcon {...iconSize} /> },
          { title: 'BNB Network', leftIcon: <BnbIcon {...iconSize} /> },
        ],
      },
      {
        season: 'q2',
        achivements: [
          { title: 'Wallet Launch', leftIcon: <FlagIcon {...iconSize} /> },
          { title: 'Solana Network', leftIcon: <SolanaIcon {...iconSize} /> },
          { title: 'Cosmos Network', leftIcon: <AtomIcon {...iconSize} /> },
          { title: 'Cross-chain Swaps' },
          { title: 'On-ramp' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'Wallet Connect' },
          { title: 'dApps' },
          { title: 'Asset Management' },
          { title: 'Ripple Network', leftIcon: <XRPIcon {...iconSize} /> },
          {
            title: 'Avalanche Network',
            leftIcon: <AvalancheIcon {...iconSize} />,
          },
          {
            title: 'Polygon Network',
            leftIcon: <PolygonIcon {...iconSize} />,
          },
          {
            title: 'BASE Network',
            leftIcon: <BaseIcon {...iconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'TRON Staking' },
          { title: 'ETH Staking' },
          { title: 'BNB Staking' },
          { title: 'Bonus Program' },
          { title: 'TON Network', leftIcon: <TonIcon {...iconSize} /> },
          {
            title: 'Arbitrum Network',
            leftIcon: <ArbitrumIcon {...iconSize} />,
          },
          { title: 'Fantom Network', leftIcon: <FantomIcon {...iconSize} /> },
        ],
      },
    ],
  },
  {
    year: '2025',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'Gas Free Transactions' },
          { title: 'Localization' },
          { title: 'Near Network', leftIcon: <NearIcon {...iconSize} /> },
          {
            title: 'Polkadot Network',
            leftIcon: <PolkadotIcon {...iconSize} />,
          },
          { title: 'Aptos Network', leftIcon: <AptosIcon {...iconSize} /> },
        ],
      },
      {
        season: 'q2',
        achivements: [
          { title: 'Tokenized Assets' },
          { title: 'NFT' },
          { title: 'CEX Swaps' },
          { title: 'New Staking Options' },
          { title: 'Portfolio' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'AI Portfolio Strategy' },
          { title: 'Stellar Network', leftIcon: <StellarIcon {...iconSize} /> },
          { title: 'SUI Network', leftIcon: <SuiIcon {...iconSize} /> },
          {
            title: 'Celestia Network',
            leftIcon: <CelestiaIcon {...iconSize} />,
          },
          {
            title: 'Algorand Network',
            leftIcon: <AlgorandIcon {...iconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'Merchant Service' },
          { title: 'P2P by Haqqex' },
        ],
      },
    ],
  },
];

const tabletIconSize = { width: '2.604vw', height: '2.604vw' };

export const tabletData = [
  {
    year: '2022',
    seasons: [
      {
        season: 'q4',
        achivements: [
          { title: 'Defining the Vision and Mission' },
          { title: 'Team Assembly' },
        ],
      },
    ],
  },
  {
    year: '2023',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'Research Markets' },
          { title: 'Research Competitors' },
          { title: 'Define Positioning' },
        ],
      },
      {
        season: 'q2',
        achivements: [
          { title: 'Backend Development Started' },
          { title: 'Architecture Development' },
          { title: 'Wallet Creation & Registration' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'Seedless Recovery' },
          { title: 'Mobile Frontend Development started' },
          {
            title: 'Ethereum Network',
            leftIcon: <EthereumIcom {...tabletIconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'HAQQ Network', leftIcon: <HaqqIcon {...tabletIconSize} /> },
          { title: 'TRON Network', leftIcon: <TronIcon {...tabletIconSize} /> },
        ],
      },
    ],
  },
  {
    year: '2024',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'On-chain Swaps' },
          {
            title: 'Bitcoin Network',
            leftIcon: <BtcIcon {...tabletIconSize} />,
          },
          { title: 'BNB Network', leftIcon: <BnbIcon {...tabletIconSize} /> },
        ],
      },
      {
        season: 'q2',
        achivements: [
          {
            title: 'Wallet Launch',
            leftIcon: <FlagIcon {...tabletIconSize} />,
          },
          {
            title: 'Solana Network',
            leftIcon: <SolanaIcon {...tabletIconSize} />,
          },
          {
            title: 'Cosmos Network',
            leftIcon: <AtomIcon {...tabletIconSize} />,
          },
          { title: 'Cross-chain Swaps' },
          { title: 'On-ramp' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'Wallet Connect' },
          { title: 'dApps' },
          { title: 'Asset Management' },
          {
            title: 'Ripple Network',
            leftIcon: <XRPIcon {...tabletIconSize} />,
          },
          {
            title: 'Avalanche Network',
            leftIcon: <AvalancheIcon {...tabletIconSize} />,
          },
          {
            title: 'Polygon Network',
            leftIcon: <PolygonIcon {...tabletIconSize} />,
          },
          {
            title: 'BASE Network',
            leftIcon: <BaseIcon {...tabletIconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'TRON Staking' },
          { title: 'ETH Staking' },
          { title: 'BNB Staking' },
          { title: 'Bonus Program' },
          { title: 'TON Network', leftIcon: <TonIcon {...tabletIconSize} /> },
          {
            title: 'Arbitrum Network',
            leftIcon: <ArbitrumIcon {...tabletIconSize} />,
          },
          {
            title: 'Fantom Network',
            leftIcon: <FantomIcon {...tabletIconSize} />,
          },
        ],
      },
    ],
  },
  {
    year: '2025',
    seasons: [
      {
        season: 'q1',
        achivements: [
          { title: 'Gas Free Transactions' },
          { title: 'Localization' },
          { title: 'Near Network', leftIcon: <NearIcon {...tabletIconSize} /> },
          {
            title: 'Polkadot Network',
            leftIcon: <PolkadotIcon {...tabletIconSize} />,
          },
          {
            title: 'Aptos Network',
            leftIcon: <AptosIcon {...tabletIconSize} />,
          },
        ],
      },
      {
        season: 'q2',
        achivements: [
          { title: 'Tokenized Assets' },
          { title: 'NFT' },
          { title: 'CEX Swaps' },
          { title: 'New Staking Options' },
          { title: 'Portfolio' },
        ],
      },
      {
        season: 'q3',
        achivements: [
          { title: 'AI Portfolio Strategy' },
          {
            title: 'Stellar Network',
            leftIcon: <StellarIcon {...tabletIconSize} />,
          },
          { title: 'SUI Network', leftIcon: <SuiIcon {...tabletIconSize} /> },
          {
            title: 'Celestia Network',
            leftIcon: <CelestiaIcon {...tabletIconSize} />,
          },
          {
            title: 'Algorand Network',
            leftIcon: <AlgorandIcon {...tabletIconSize} />,
          },
        ],
      },
      {
        season: 'q4',
        achivements: [
          { title: 'Merchant Service' },
          { title: 'P2P by Haqqex' },
        ],
      },
    ],
  },
];
