import { ReactComponent as Icons } from 'src/assets/icons/icons.svg';
import {
  DescriptionTextStyled,
  DownloadApkText,
  IconsInfoTextStyled,
  MainStyled,
  MainTextStyled,
  NetworkAdditionStyled,
  StoreItem,
  StoreText,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/download-on-google-play.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/download-on-app-store.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <MainStyled>
        <MainTextStyled>Haqabi Wallet</MainTextStyled>
        <DescriptionTextStyled>
          Secure, simple and ethical crypto management
        </DescriptionTextStyled>
        <NetworkAdditionStyled>
          <Icons width={'31.467vw'} height={'7.733vw'} />

          <IconsInfoTextStyled $isMobile={true}>and more</IconsInfoTextStyled>
        </NetworkAdditionStyled>

        <StoreItemsWrapper>
          <StoreItem
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank">
            <AppStoreIcon width={'42.2vw'} height={'12vw'} />
          </StoreItem>
          <StoreItem
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank">
            <PlayMarketIcon width={'42.2vw'} height={'12vw'} />
          </StoreItem>
        </StoreItemsWrapper>
        <DownloadApkText onClick={() => navigate('/download-apk')}>
          Also available in Android APK
        </DownloadApkText>
      </MainStyled>
    </Container>
  );
};

const StoreItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 3.2vw;
`;
