import { HorizontalView } from 'src/components/FlexViews';
import styled from 'styled-components';

export const BoxStyled = styled.div`
  background: #12161b;
  padding: clamp(0px, 3.472vw, 50px) clamp(0px, 6.9vw, 100px);

  @media (max-width: 1024px) {
    padding: 6.771vw 4.167vw;
  }
  @media (max-width: 768px) {
    padding: 13.867vw 8.533vw;
  }
`;

export const Title = styled.p`
  color: var(--Neutral-1, #eaedee);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.222vw, 32px);
  font-weight: 700;
  margin-bottom: clamp(0px, 2.222vw, 32px);

  @media (max-width: 1024px) {
    font-size: 4.167vw;
  }
  @media (max-width: 768px) {
    font-size: 6.4vw;
    margin-bottom: 8.533vw;
  }
`;

export const SolutionsWrapper = styled(HorizontalView)`
  justify-content: center;
  gap: clamp(0px, 1.667vw, 24px);
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 6.4vw;
  }
`;
export const Solution = styled.div`
  height: clamp(0px, 6.944vw, 100px);
  padding: 0 clamp(0px, 6.944vw, 100px);
  border-radius: clamp(0px, 1.389vw, 20px);
  background: rgba(194, 201, 214, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 0 3.646vw;
    height: 13.021vw;
  }
  @media (max-width: 768px) {
    padding: 0 3.646vw;
    height: 21.333vw;
    width: 100%;
    border-radius: 5.333vw;
  }
`;
