import React, { useEffect, useState } from 'react';
import {
  BottomStyled,
  BottomTextStyled,
  IconBlockStyled,
  IconsStyled,
  MiddleBlockStyled,
  MiddleTextBlockStyled,
  MiddleTitleStyled,
  TopBlockStyled,
  LinkStyled,
  Title,
  ContactUs,
} from './styled';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as RedditIcon } from 'src/assets/icons/reddit.svg';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi-icon.svg';

import { Container } from 'src/components/Container/styled';
import { useNavigate } from 'react-router-dom';
import { HorizontalView } from 'src/components/FlexViews';

export const Footer = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);
  return (
    <Container>
      <TopBlockStyled>
        <HorizontalView style={{ gap: '2.4vw', justifyContent: 'center' }}>
          <HaqabiIcon width={'8vw'} height={'9vw'} />
          <Title>Haqabi</Title>
        </HorizontalView>
        <ContactUs>
          Contact us{' '}
          <span style={{ color: '#AADE16' }}>support@haqabi.com</span>
        </ContactUs>
        <IconBlockStyled>
          <IconsStyled>
            <LinkStyled href="https://twitter.com/HaqabiHQ">
              <TwitterIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://t.me/haqabi">
              <TelegramIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://discord.gg/kXeefuKPmQ">
              <DiscordIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.facebook.com/haqabi.wallet">
              <FacebookIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.linkedin.com/company/haqabi/">
              <LinkedInIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.reddit.com/r/haqabi/">
              <RedditIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
          </IconsStyled>
        </IconBlockStyled>
      </TopBlockStyled>
      <MiddleBlockStyled>
        <MiddleTextBlockStyled>
          <MiddleTitleStyled onClick={() => setElementId('Features')}>
            Features
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Comparison')}>
            Comparison
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Timeline')}>
            Roadmap
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Documentation')}>
            Documentation
          </MiddleTitleStyled>
        </MiddleTextBlockStyled>
      </MiddleBlockStyled>
      <BottomStyled>
        <BottomTextStyled>
          Copyright © 2025. All rights reserved.
        </BottomTextStyled>
      </BottomStyled>
    </Container>
  );
};
