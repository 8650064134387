import { VerticalView } from 'src/components/FlexViews';
import styled, { css } from 'styled-components';

export const BoxStyled = styled.div`
  display: flex;
  justify-content: center;
  gap: clamp(0px, 2.5vw, 36px);
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const LogoWrapper = styled.div`
  padding: clamp(0px, 1.806vw, 26px);
  background: #171b20;
  border-radius: clamp(0px, 1.389vw, 20px);

  @media (max-width: 1024px) {
    background: none;
    border-radius: none;
  }
`;
export const RightSideWrapper = styled(VerticalView)`
  align-items: flex-start;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;
export const Title = styled.p`
  color: var(--Neutral-1, #eaedee);
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 2.222vw, 32px);
  font-weight: 700;
  margin-bottom: clamp(0px, 0.556vw, 8px);

  @media (max-width: 1024px) {
    font-size: 4.167vw;
    margin-bottom: 1.042vw;
  }
  @media (max-width: 768px) {
    font-size: 8.533vw;
    text-align: center;
  }
`;
export const DescriptionText = styled.p`
  margin-bottom: clamp(0px, 0.556vw, 8px);
  color: var(--Neutral-4, #8f96a3);
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  line-height: 140%;

  @media (max-width: 1024px) {
    font-size: 2.083vw;
    margin-bottom: 1.042vw;
  }
  @media (max-width: 768px) {
    font-size: 4.267vw;
    text-align: center;
  }
`;
export const LearnMoreText = styled.a`
  padding: clamp(0px, 0.833vw, 12px) 0;
  color: var(--Primary-4, #c4f538);
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 0.972vw, 14px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.12px;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 1.823vw;
  }
  @media (max-width: 768px) {
    font-size: 3.733vw;
  }
`;
export const ImgageStyled = styled.img`
  width: clamp(0px, 19.86vw, 286px);
  height: clamp(0px, 3.819vw, 55px);
  @media (max-width: 1024px) {
    width: 29.688vw;
    height: 5.729vw;
  }
  @media (max-width: 768px) {
    width: 40vw;
    height: 8vw;
  }
`;
