import { Container } from 'src/components/Container/styled';
import {
  AchivementsWrapper,
  AchivementTextStyled,
  AchivementWrapper,
  RoadmapYearText,
  SeasonAchivementsWrapper,
  SeasonsAchivementsWrapper,
  SeasonText,
  TitleStyled,
  YearAchivementsWrapper,
} from './styled';

import { data, tabletData } from './data';

export const ProjectTimelineDesktoop = ({ device }) => {
  const { isTablet } = device;
  return (
    <Container id="Roadmap">
      <TitleStyled>Project Roadmap</TitleStyled>
      {(isTablet ? tabletData : data).map((roadMap, idx) => (
        <YearAchivementsWrapper key={idx}>
          <RoadmapYearText>{roadMap.year}</RoadmapYearText>
          <SeasonsAchivementsWrapper>
            {roadMap.seasons.map((season, idx) => (
              <SeasonAchivementsWrapper key={idx}>
                <SeasonText>{season.season}</SeasonText>
                <AchivementsWrapper>
                  {season.achivements.map((achivement, idx) => (
                    <AchivementWrapper
                      key={idx}
                      style={{
                        background:
                          achivement.title === 'Wallet Launch' &&
                          'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(147, 194, 15, 0.50)',
                      }}>
                      {achivement.leftIcon}
                      <AchivementTextStyled>
                        {achivement.title}
                      </AchivementTextStyled>
                    </AchivementWrapper>
                  ))}
                </AchivementsWrapper>
              </SeasonAchivementsWrapper>
            ))}
          </SeasonsAchivementsWrapper>
        </YearAchivementsWrapper>
      ))}
    </Container>
  );
};
