import styled from 'styled-components';

export const BoxStyled = styled.div`
  background: radial-gradient(
      205.2% 68.06% at 83.58% 50.11%,
      rgba(198, 213, 128, 0.1) 0%,
      rgba(176, 203, 98, 0) 100%
    ),
    #12161b;
  padding: 12.8vw 6.4vw;
`;

export const Title = styled.p`
  color: #c4f538;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 4.8vw;
  margin-bottom: 2.133vw;
`;
export const GameName = styled.p`
  color: #eaedee;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 8.533vw;
  margin-bottom: 8.533vw;
`;
export const GameInfoList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4.267vw;
  margin-bottom: 19.2vw;
`;
export const GameInfoItem = styled.li`
  position: relative;
  left: 2.933vw;
  &::before {
    content: '';
    position: absolute;
    width: 1.6vw;
    height: 1.6vw;
    left: -2.933vw;
    top: 0.8vw;
    display: block;
    border-radius: 50%;
    background-color: #979eaa;
  }
`;
export const GameInfoItemTitle = styled.p`
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
`;
export const GameInfoItemDescription = styled.span`
  color: #979eaa;
  font-weight: 400;
`;
