import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const DownloadAppButtonStyled = styled.button`
  padding: clamp(0px, 1.111vw, 16px) clamp(0px, 2.222vw, 32px);
  border-radius: 12px;
  background: #c4f538;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: clamp(0px, 1.111vw, 16px);
  cursor: pointer;
  @media (max-width: 1024px) {
    font-size: 2.083vw;
    padding: 2.083vw 4.167vw;
  }
  @media (max-width: 768px) {
    border-radius: 3.2vw;
    font-size: 4.267vw;
    padding: 4.267vw 8.533vw;
  }

  &:hover {
    background-color: #c8f646;
  }
  &:active {
    background-color: #b6e434;
  }
`;

export const DownloadAppButton = () => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return () => setElementId('');
  }, [elementId, navigate]);
  return (
    <DownloadAppButtonStyled onClick={() => setElementId('DownloadApp')}>
      Download App
    </DownloadAppButtonStyled>
  );
};
