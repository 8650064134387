import { Container } from 'src/components/Container/styled';
import {
  BoxStyled,
  DescriptionText,
  LeftSideWrapper,
  Socialsrapper,
  TelegramWrapper,
  Title,
  TelegramText,
  MembersText,
  RightSideWrapper,
  CalendarItem,
  CalendarList,
  CalendarTitle,
  CalendarDay,
  CalendarDayInfo,
  SocialLinkStyled,
} from './styled';
import { HorizontalView } from 'src/components/FlexViews';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram-2.svg';
import { ReactComponent as Twitter2Icon } from 'src/assets/icons/twitter-2.svg';
import { ReactComponent as Discord2Icon } from 'src/assets/icons/discord-2.svg';
import { ReactComponent as CalendarCheck } from 'src/assets/icons/calendar-check-2.svg';

const size = {
  width: '20px',
  height: '20px',
};

const mobileSize = {
  width: '5.333vw',
  height: '5.333vw',
};

const calendarMobileData = [
  {
    day: 'MON',
    icon: <TelegramIcon {...mobileSize} />,
    title: 'Screenshot Challenge',
  },
  {
    day: 'TUE',
    icon: <TelegramIcon {...mobileSize} />,
    title: 'Crypto Quiz',
  },
  {
    day: 'WED',
    icon: <Discord2Icon {...mobileSize} />,
    title: 'Haqabi Gaming',
  },
  {
    day: 'THU',
    icon: <Twitter2Icon {...mobileSize} />,
    title: 'Community Call',
  },
  {
    day: 'FRI',
    icon: <Discord2Icon {...mobileSize} />,
    title: 'Technical Analysis',
  },
];

const calendarData = [
  {
    day: 'MON',
    icon: <TelegramIcon {...size} />,
    title: 'Screenshot Challenge',
  },
  {
    day: 'TUE',
    icon: <TelegramIcon {...size} />,
    title: 'Crypto Quiz',
  },
  {
    day: 'WED',
    icon: <Discord2Icon {...size} />,
    title: 'Haqabi Gaming',
  },
  {
    day: 'THU',
    icon: <Twitter2Icon {...size} />,
    title: 'Community Call',
  },
  {
    day: 'FRI',
    icon: <Discord2Icon {...size} />,
    title: 'Technical Analysis',
  },
];

export const AppInfo = ({ device }) => {
  const { isMobile } = device;
  return (
    <Container>
      <BoxStyled>
        <LeftSideWrapper>
          <Title>Discover, Engage, Earn</Title>
          <DescriptionText>
            Haqabi isn’t just a wallet — it’s a community built on transparency,
            ethics, and education. Engage, learn, and earn while shaping a more
            ethical digital economy.
          </DescriptionText>
          {isMobile && (
            <TelegramWrapper href="https://t.me/haqabi" target="_blank">
              <TelegramIcon
                width={isMobile ? '7.467vw' : '28px'}
                height={isMobile ? '7.467vw' : '28px'}
                color="#1F2630"
              />
              <TelegramText>Join our telegram</TelegramText>
            </TelegramWrapper>
          )}
          <HorizontalView>
            <Socialsrapper>
              {!isMobile && (
                <TelegramWrapper href="https://t.me/haqabi" target="_blank">
                  <TelegramIcon color="#1F2630" />
                  <TelegramText>Join our telegram</TelegramText>
                </TelegramWrapper>
              )}
              <SocialLinkStyled
                href="https://discord.gg/kXeefuKPmQ"
                target="_blank">
                <Discord2Icon
                  width={isMobile ? '12.8vw' : '48px'}
                  height={isMobile ? '12.8vw' : '48px'}
                />
              </SocialLinkStyled>
              <SocialLinkStyled
                href="https://twitter.com/HaqabiHQ"
                target="_blank">
                <Twitter2Icon
                  width={isMobile ? '12.8vw' : '48px'}
                  height={isMobile ? '12.8vw' : '48px'}
                />
              </SocialLinkStyled>
            </Socialsrapper>
            <MembersText>90k+ members</MembersText>
          </HorizontalView>
        </LeftSideWrapper>
        <RightSideWrapper>
          <HorizontalView
            style={{
              justifyContent: 'space-between',
              height: isMobile ? '14.933vw' : '56px',
            }}>
            <CalendarTitle>Daily events calendar</CalendarTitle>
            <CalendarCheck
              width={isMobile ? '6.4vw' : '24px'}
              height={isMobile ? '6.4vw' : '24px'}
            />
          </HorizontalView>
          <CalendarList>
            {(isMobile ? calendarMobileData : calendarData).map((day, idx) => (
              <CalendarItem
                style={{
                  height: isMobile ? '14.933vw' : 'clamp(0px, 3.889vw, 56px)',
                }}
                key={idx}>
                <CalendarDay>{day.day}</CalendarDay>
                {day.icon}
                <CalendarDayInfo>{day.title}</CalendarDayInfo>
              </CalendarItem>
            ))}
          </CalendarList>
        </RightSideWrapper>
      </BoxStyled>
    </Container>
  );
};
