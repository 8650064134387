import { Container } from 'src/components/Container/styled';
import { DownloadAppButton } from '../DownloadAppButton';
import { InfoText, LeftSideWrapper, Title, BoxStyled } from './styled';

export const DappsDesktop = () => {
  return (
    <Container>
      <BoxStyled>
        <LeftSideWrapper>
          <Title>Connect with DApps through your wallet!</Title>
          <InfoText>
            We work with such Dapps as 1inch, SushiSwap, Uniswap, Aave and{' '}
            <span style={{ color: '#C4F538' }}>many others</span>{' '}
          </InfoText>
          <DownloadAppButton />
        </LeftSideWrapper>
      </BoxStyled>
    </Container>
  );
};
