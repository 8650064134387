import { VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
import haqabiBackground from 'src/assets/img/haqabi-background.webp';
import haqabiBackgroundOpacity from 'src/assets/img/haqabi-background-opacity.webp';
import haqabiAppOpacity from 'src/assets/img/haqabi-app-opacity.webp';

export const BoxStyled = styled.div`
  padding: 6.25vw 6.944vw;
  background-color: #12161b;
  background-image: url(${haqabiBackground});
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;

  @media (min-width: 1440px) {
    padding: 90px 100px;
  }

  @media (max-width: 1024px) {
    background-image: url(${haqabiBackgroundOpacity});
  }

  @media (max-width: 768px) {
    padding: 11.733vw 6.4vw;
    background-size: 90%;
    background-position: 10vw 55vw;
    background-image: url(${haqabiAppOpacity});
  }
`;

export const LeftSideWrapper = styled(VerticalView)`
  max-width: 55%;
  @media (max-width: 1100px) {
    max-width: 100%;
  }
`;
export const PlayMarketText = styled.p`
  color: #666c77;
  font-family: Inter, sans-serif;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 3.733vw;
  }
`;
export const RareScore = styled.p`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;
export const Title = styled.p`
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin: clamp(0px, 1.111vw, 16px) 0;
  @media (max-width: 768px) {
    margin: clamp(0px, 49.333vw, 16px) 0;
  }
`;
export const DescriptionText = styled.p`
  color: #979eaa;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 68px;
  font-size: clamp(0px, 1.111vw, 16px);

  @media (max-width: 768px) {
    margin-bottom: 14.933vw;
    font-size: 3.733vw;
  }
`;

export const StoreItem = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid rgba(149, 153, 158, 0.9);
  background-color: #000;
  padding: 0;
  border-radius: 8px;
  gap: clamp(0px, 1.389vw, 20px);
  padding: ${({ isMobile }) => (isMobile ? '2.5vw 4vw' : '')};
`;

export const StoresView = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
