import { useEffect, useState } from 'react';
import { ReactComponent as BurgerMenuIcon } from 'src/assets/icons/burger-menu.svg';
import { ReactComponent as CloseMenuIcon } from 'src/assets/icons/close-menu.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as HaqabiIcon } from 'src/assets/icons/haqabi-icon.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter.svg';
import { ReactComponent as RedditIcon } from 'src/assets/icons/reddit.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as DownloadApkIcon } from 'src/assets/icons/download-apk.svg';

import {
  ButtonStyled,
  HeaderWrapper,
  MenuWrapper,
  TitleStyled,
  ButtonsWrapper,
  TextStyled,
  StoresWrapper,
} from './styled';
import { Container } from 'src/components/Container/styled';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { HorizontalView } from 'src/components/FlexViews';
import { DownloadAppButton } from '../DownloadAppButton';

export const Header = ({ isMain, device }) => {
  const isTablet = device?.isTablet;
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const body = document.body;
    if (menuOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  }, [menuOpen]);
  const [elementId, setElementId] = useState('');

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);

  return (
    <Container>
      <MenuWrapper style={{ overflow: 'auto' }} $menuOpen={menuOpen}>
        <HeaderWrapper>
          {menuOpen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3.467vw',
                  }}>
                  <HaqabiIcon width={'7.467vw'} height={'8.533vw'} />
                  <TitleStyled>HAQABI</TitleStyled>
                </div>
                <div onClick={() => setMenuOpen(false)}>
                  <CloseMenuIcon width="8vw" height="8vw" />
                </div>
              </div>
              <ButtonsWrapper onClick={() => setMenuOpen(false)}>
                <ButtonStyled onClick={() => setElementId('Features')}>
                  Features
                </ButtonStyled>
                <ButtonStyled onClick={() => setElementId('Comparison')}>
                  Comparison
                </ButtonStyled>
                <ButtonStyled onClick={() => setElementId('Roadmap')}>
                  Roadmap
                </ButtonStyled>
                <ButtonStyled onClick={() => setElementId('Documentation')}>
                  Documentation
                </ButtonStyled>
              </ButtonsWrapper>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <a href="https://twitter.com/HaqabiHQ">
                  <TwitterIcon
                    style={{ color: 'transparent' }}
                    width={'12vw'}
                    height={'12vw'}
                  />
                </a>
                <a href="https://t.me/haqabi">
                  <TelegramIcon width={'12vw'} height={'12vw'} />
                </a>
                <a href="https://discord.gg/kXeefuKPmQ">
                  <DiscordIcon width={'12vw'} height={'12vw'} />
                </a>
                <a href="https://www.facebook.com/haqabi.wallet">
                  <FacebookIcon width={'12vw'} height={'12vw'} />
                </a>
                <a href="https://www.linkedin.com/company/haqabi/">
                  <LinkedInIcon width={'12vw'} height={'12vw'} />
                </a>
                <a href="https://www.reddit.com/r/haqabi/">
                  <RedditIcon width={'12vw'} height={'12vw'} />
                </a>
              </div>
              <TextStyled>Join the Community</TextStyled>
            </div>
          )}
        </HeaderWrapper>
      </MenuWrapper>
      <HeaderWrapper>
        <div style={{ display: 'flex', alignItems: 'center', gap: '13px' }}>
          <HaqabiIcon width={'7.467vw'} height={'8.533vw'} />
          <TitleStyled>HAQABI</TitleStyled>
        </div>
        <HorizontalView
          style={isTablet ? { gap: '2.604vw' } : undefined}
          onClick={() => setMenuOpen(true)}>
          {isTablet && <DownloadAppButton />}
          <BurgerMenuIcon width={'6.4vw'} height={'6.4vw'} />
        </HorizontalView>
      </HeaderWrapper>
    </Container>
  );
};

const InfoTextStyled = styled.p`
  color: #eaedee;
  font-size: 3.733vw;
  line-height: 4.8vw;
  margin-bottom: 2.133vw;
  &:nth-child(3) {
    margin-bottom: 4.2vw;
  }
`;

const DownloadElementWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.733vw 3.2vw;
  border-radius: 2.133vw;
  border-color: #6a7484;
  border-width: 1px;
  border-style: solid;
  background-color: #29313e;
  gap: 2.133vw;
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
`;

const DowloadText = styled.p`
  font-size: 3.2vw;
  color: #eaedee;
  font-family: Inter, sans-serif;
  white-space: nowrap;
`;
